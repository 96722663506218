@import url('https://fonts.googleapis.com/css?family=Lato|Open+Sans|Roboto');

*{
  font-family: 'Roboto', sans-serif !important;
}

body {
  margin: 0;
  margin-top:50px;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("/images/sports_icons.png");
  background-size: cover;
  z-index: -10; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.nav{
  position: fixed !important;
  height: 50px;
  background: white;
  border: black 1px solid;
  z-index: 1;
}

.nav-text{
  /* width: 30%; */
  padding: 10px;
  color: #025353;
  margin: auto 0;
}

.about-section{
  margin-top:-6px;
}

.menu{
  position: absolute;
  right: 30px;
  top: 5px;
  font-size: 45px;
}

.teal-text{
  color: #025353;
}

.teal-bar{
  background: #025353;
  padding: 30px 20px;
}

.white-text{
  color: white;
}

.background img{
  width: 100%;
  height: 100%;
}

.photo{
  position: relative;
  width: 200px;
  bottom: 258.5px;
}

.quote{
  color: #025353;
  position: absolute;
  top: 120px;
  left: 50px;
  font-weight: 00;
  letter-spacing: 1px;
  text-shadow: 2px 2px 10px rgba(252, 206, 0, 0.5),
  -1px -1px 0 black,
  1px -1px 0 black,
  -1px 1px 0 black,
  1px 1px 0 black; 
}

.about-text{
  margin-top: 50px;
  line-height: 20px;
}

.about-2{
  padding-right: 65px;
}

.about-3{
  margin-right:10px
}

.about-header{
  letter-spacing: 5px;
  text-transform: uppercase;
  line-height: 40px;
}

.section{
  margin-top: 80px
}

.project, .skill{
  width: 90% !important;
  margin-top: 50px;
  box-shadow: 1px 3px 5px 1px rgba(0,0,0,0.2), 1px 5px 10px 1px rgba(0,0,0,0.15), inset 0px 1px 0px 1px rgba(0,0,0,0.15);
}

.project-image{
  text-align: center !important;
}

.project-name{
  padding: 20px 10px 0 10px;
  text-align: left;
}

.project p{
  padding: 20px 10px 0 10px;
  text-align: left;
}

.links a{
  padding: 10px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.links{
  display: flex;
  justify-content: space-evenly;
}

.inline{
  display: inline;
}

.skills{
  padding: 10px;
  max-height: 100px;
  display: block;
}

.skills-row{
  padding-bottom: 30px;
}

.skill-header{
  text-align: left;
  padding: 20px;
}

.skill-header:after, .project-name:after, .tech-stack:after{
  content: "";
  display: block;
  margin: 0;
  width: 98%; 
  padding-top: 20px; 
  border-bottom: 1px solid #025353; 
}

.tech-stack:after{
  padding-top: 10px;
  width: 95%;
  margin: 0;
}

.tech-stack{
  text-align: left;
  padding-left: 30px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.tech-stack-header{
  font-size: 120%;
  text-align: left;
  margin-bottom: 10px;
}

.front-stack, .back-stack{
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.stack-item{
  padding: 5px 0 0 10px;
}

.file{
  width: 30px;
  height: 30px;
  color: white;
}

.social-icons{
  display: flex;
  justify-content: space-evenly;
  margin:20px 0;
  max-width: 1000px;
}

.social-icons a{
  text-decoration: none;
}

.social-icon{
  transition: all .5s ease;
  margin-bottom: 5px;
}

.social-icon:hover{
  -webkit-transform: rotate(360deg);
  -webkit-transition: -webkit-transform .5s ease-in-out;
  background: #025353;
}

.contact-label{
  color: #025353;
}

.file-background{
  display: inline-block;
  border: #6A687A 1px solid;
  border-radius: 100%;
  padding: 11px;
  /* width: 30px; */
  position: relative;
  top: 8px;
  background: #593C8F;
}

.contact-message{
  padding-top: 30px;
  margin-bottom: -40px;
}

.float-right{
  float: right;
}

.float-left{
  float: left;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.about-section::before , .section::before{ 
  display: block; 
  content: " "; 
  margin-top: -57px; 
  height: 50px; 
  visibility: hidden; 
  pointer-events: none;
}

.overlay{
  position: fixed; /* Sit on top of the page content */
 /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;

  /* background-color: rgba(67, 70, 247, .97); */
  background-color: rgba(2, 83, 83, .97); /* Black background with opacity */
  z-index: 2; 
  cursor: pointer;
  display: flex;
  align-items : center;
}

.menu-options a{
  font-size: 60px;
  line-height: 60px;
  font-family: 'Open Sans';
  font-weight: 700;
  letter-spacing: -1px;
  text-decoration: none;
  color: #e4e4e4;
}

.menu-options a:hover { 
  color: white
}

.menu-options li{
  padding:10px 0;
}

.left-border{
  border-left: #025353 4px solid;
  padding-left: 10px;
  margin: 40px 0;
}

@media screen and (max-width: 440px){
  .nav-text h2{
    font-size: 1.4em;
  }
  .menu{
    font-size: 35px;
    top: 8px;
  }
  .about-header{
    text-align: left !important;
  }

}

@media screen and (max-width: 1000px){
  .background img{
    position: relative;
    top: 52px;
  }
  .about-section{
    margin-top: 46px;
  }
  .quote{
    top: 100px !important; 
  }
  .about-section::before, .section::before {
    height: 56px;
}
}

@media screen and (max-width: 750px){
  .quote{
    font-size: 25px !important;
  }
}

@media screen and (max-width: 630px){
  .quote{
    font-size: 20px !important;
    top: 70px !important;
    left: 20px;
  }
}

@media screen and (max-width: 400px){
  .quote{
    display: none;
  }
}