@import url(https://fonts.googleapis.com/css?family=Lato|Open+Sans|Roboto);
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Lato|Open+Sans|Roboto);
*{
  font-family: 'Roboto', sans-serif !important;
}

body {
  margin: 0;
  margin-top:50px;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("/images/sports_icons.png");
  background-size: cover;
  z-index: -10; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.nav{
  position: fixed !important;
  height: 50px;
  background: white;
  border: black 1px solid;
  z-index: 1;
}

.nav-text{
  /* width: 30%; */
  padding: 10px;
  color: #025353;
  margin: auto 0;
}

.about-section{
  margin-top:-6px;
}

.menu{
  position: absolute;
  right: 30px;
  top: 5px;
  font-size: 45px;
}

.teal-text{
  color: #025353;
}

.teal-bar{
  background: #025353;
  padding: 30px 20px;
}

.white-text{
  color: white;
}

.background img{
  width: 100%;
  height: 100%;
}

.photo{
  position: relative;
  width: 200px;
  bottom: 258.5px;
}

.quote{
  color: #025353;
  position: absolute;
  top: 120px;
  left: 50px;
  font-weight: 00;
  letter-spacing: 1px;
  text-shadow: 2px 2px 10px rgba(252, 206, 0, 0.5),
  -1px -1px 0 black,
  1px -1px 0 black,
  -1px 1px 0 black,
  1px 1px 0 black; 
}

.about-text{
  margin-top: 50px;
  line-height: 20px;
}

.about-2{
  padding-right: 65px;
}

.about-3{
  margin-right:10px
}

.about-header{
  letter-spacing: 5px;
  text-transform: uppercase;
  line-height: 40px;
}

.section{
  margin-top: 80px
}

.project, .skill{
  width: 90% !important;
  margin-top: 50px;
  box-shadow: 1px 3px 5px 1px rgba(0,0,0,0.2), 1px 5px 10px 1px rgba(0,0,0,0.15), inset 0px 1px 0px 1px rgba(0,0,0,0.15);
}

.project-image{
  text-align: center !important;
}

.project-name{
  padding: 20px 10px 0 10px;
  text-align: left;
}

.project p{
  padding: 20px 10px 0 10px;
  text-align: left;
}

.links a{
  padding: 10px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.links{
  display: flex;
  justify-content: space-evenly;
}

.inline{
  display: inline;
}

.skills{
  padding: 10px;
  max-height: 100px;
  display: block;
}

.skills-row{
  padding-bottom: 30px;
}

.skill-header{
  text-align: left;
  padding: 20px;
}

.skill-header:after, .project-name:after, .tech-stack:after{
  content: "";
  display: block;
  margin: 0;
  width: 98%; 
  padding-top: 20px; 
  border-bottom: 1px solid #025353; 
}

.tech-stack:after{
  padding-top: 10px;
  width: 95%;
  margin: 0;
}

.tech-stack{
  text-align: left;
  padding-left: 30px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.tech-stack-header{
  font-size: 120%;
  text-align: left;
  margin-bottom: 10px;
}

.front-stack, .back-stack{
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.stack-item{
  padding: 5px 0 0 10px;
}

.file{
  width: 30px;
  height: 30px;
  color: white;
}

.social-icons{
  display: flex;
  justify-content: space-evenly;
  margin:20px 0;
  max-width: 1000px;
}

.social-icons a{
  text-decoration: none;
}

.social-icon{
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  margin-bottom: 5px;
}

.social-icon:hover{
  -webkit-transform: rotate(360deg);
  -webkit-transition: -webkit-transform .5s ease-in-out;
  background: #025353;
}

.contact-label{
  color: #025353;
}

.file-background{
  display: inline-block;
  border: #6A687A 1px solid;
  border-radius: 100%;
  padding: 11px;
  /* width: 30px; */
  position: relative;
  top: 8px;
  background: #593C8F;
}

.contact-message{
  padding-top: 30px;
  margin-bottom: -40px;
}

.float-right{
  float: right;
}

.float-left{
  float: left;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.about-section::before , .section::before{ 
  display: block; 
  content: " "; 
  margin-top: -57px; 
  height: 50px; 
  visibility: hidden; 
  pointer-events: none;
}

.overlay{
  position: fixed; /* Sit on top of the page content */
 /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;

  /* background-color: rgba(67, 70, 247, .97); */
  background-color: rgba(2, 83, 83, .97); /* Black background with opacity */
  z-index: 2; 
  cursor: pointer;
  display: flex;
  align-items : center;
}

.menu-options a{
  font-size: 60px;
  line-height: 60px;
  font-family: 'Open Sans';
  font-weight: 700;
  letter-spacing: -1px;
  text-decoration: none;
  color: #e4e4e4;
}

.menu-options a:hover { 
  color: white
}

.menu-options li{
  padding:10px 0;
}

.left-border{
  border-left: #025353 4px solid;
  padding-left: 10px;
  margin: 40px 0;
}

@media screen and (max-width: 440px){
  .nav-text h2{
    font-size: 1.4em;
  }
  .menu{
    font-size: 35px;
    top: 8px;
  }
  .about-header{
    text-align: left !important;
  }

}

@media screen and (max-width: 1000px){
  .background img{
    position: relative;
    top: 52px;
  }
  .about-section{
    margin-top: 46px;
  }
  .quote{
    top: 100px !important; 
  }
  .about-section::before, .section::before {
    height: 56px;
}
}

@media screen and (max-width: 750px){
  .quote{
    font-size: 25px !important;
  }
}

@media screen and (max-width: 630px){
  .quote{
    font-size: 20px !important;
    top: 70px !important;
    left: 20px;
  }
}

@media screen and (max-width: 400px){
  .quote{
    display: none;
  }
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/**
*** SIMPLE GRID
*** (C) ZACH COLE 2016
**/

/* UNIVERSAL */

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}

/* ::selection{
  background-color: #025353;
  color: white;
} */

/* ROOT FONT STYLES */

* {
  font-family: 'Roboto', 'Lato', 'Helvetica';
  line-height: 1.5;
}

/* TYPOGRAPHY */

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-heavy {
  font-weight: 700;
}

/* POSITIONING */

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

/* ==== GRID SYSTEM ==== */

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  position: relative;
  width: 100%;
}

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%;
}

.col-1-sm {
  width: 4.33%;
}

.col-2-sm {
  width: 12.66%;
}

.col-3-sm {
  width: 21%;
}

.col-4-sm {
  width: 29.33%;
}

.col-5-sm {
  width: 37.66%;
}

.col-6-sm {
  width: 46%;
}

.col-7-sm {
  width: 54.33%;
}

.col-8-sm {
  width: 62.66%;
}

.col-9-sm {
  width: 71%;
}

.col-10-sm {
  width: 79.33%;
}

.col-11-sm {
  width: 87.66%;
}

.col-12-sm {
  width: 96%;
}

.row::after {
	content: "";
	display: table;
	clear: both;
}

.hidden-sm {
  display: none;
}

@media only screen and (min-width: 33.75em) {  /* 540px */
  .container {
    width: 80%;
  }
}

@media only screen and (min-width: 55em) {  /* 720px */
  .col-1 {
    width: 4.33%;
  }

  .col-2 {
    width: 12.66%;
  }

  .col-3 {
    width: 21%;
  }

  .col-4 {
    width: 29.33%;
  }

  .col-5 {
    width: 37.66%;
  }

  .col-6 {
    width: 46%;
  }

  .col-7 {
    width: 54.33%;
  }

  .col-8 {
    width: 62.66%;
  }

  .col-9 {
    width: 71%;
  }

  .col-10 {
    width: 79.33%;
  }

  .col-11 {
    width: 87.66%;
  }

  .col-12 {
    width: 96%;
  }

  .hidden-sm {
    display: block;
  }
}

@media only screen and (min-width: 60em) { /* 960px */
  .container {
    width: 80%;
    max-width: 75rem;
  }
}

.flow-text {
  font-weight: 300
}

@media only screen and (min-width: 360px) {
  .flow-text {
      font-size:1.2rem;
  }
}

@media only screen and (min-width: 0px) {
  .flow-text {
      line-height:0.8rem;
  }
}

@media only screen and (min-width: 390px) {
  .flow-text {
      font-size:1.224rem;
  }
}

@media only screen and (min-width: 30px) {
  .flow-text {
      line-height:0.904rem;
  }
}

@media only screen and (min-width: 420px) {
  .flow-text {
      font-size:1.248rem;
  }
}

@media only screen and (min-width: 60px) {
  .flow-text {
      line-height:1.008rem;
  }
}

@media only screen and (min-width: 450px) {
  .flow-text {
      font-size:1.272rem;
  }
}

@media only screen and (min-width: 90px) {
  .flow-text {
      line-height:1.112rem;
  }
}

@media only screen and (min-width: 480px) {
  .flow-text {
      font-size:1.296rem;
  }
}

@media only screen and (min-width: 120px) {
  .flow-text {
      line-height:1.216rem;
  }
}

@media only screen and (min-width: 510px) {
  .flow-text {
      font-size:1.32rem;
  }
}

@media only screen and (min-width: 150px) {
  .flow-text {
      line-height:1.32rem;
  }
}

@media only screen and (min-width: 540px) {
  .flow-text {
      font-size:1.344rem;
  }
}

@media only screen and (min-width: 180px) {
  .flow-text {
      line-height:1.424rem;
  }
}

@media only screen and (min-width: 570px) {
  .flow-text {
      font-size:1.368rem;
  }
}

@media only screen and (min-width: 210px) {
  .flow-text {
      line-height:1.528rem;
  }
}

@media only screen and (min-width: 600px) {
  .flow-text {
      font-size:1.392rem;
  }
}

@media only screen and (min-width: 240px) {
  .flow-text {
      line-height:1.632rem;
  }
}

@media only screen and (min-width: 630px) {
  .flow-text {
      font-size:1.416rem;
  }
}

@media only screen and (min-width: 270px) {
  .flow-text {
      line-height:1.736rem;
  }
}

@media only screen and (min-width: 660px) {
  .flow-text {
      font-size:1.44rem;
  }
}

@media only screen and (min-width: 300px) {
  .flow-text {
      line-height:1.84rem;
  }
}

@media only screen and (min-width: 690px) {
  .flow-text {
      font-size:1.464rem;
  }
}

@media only screen and (min-width: 330px) {
  .flow-text {
      line-height:1.944rem;
  }
}

@media only screen and (min-width: 720px) {
  .flow-text {
      font-size:1.488rem;
  }
}

@media only screen and (min-width: 360px) {
  .flow-text {
      line-height:2.048rem;
  }
}

@media only screen and (min-width: 750px) {
  .flow-text {
      font-size:1.512rem;
  }
}

@media only screen and (min-width: 390px) {
  .flow-text {
      line-height:2.152rem;
  }
}

@media only screen and (min-width: 780px) {
  .flow-text {
      font-size:1.536rem;
  }
}

@media only screen and (min-width: 420px) {
  .flow-text {
      line-height:2.256rem;
  }
}

@media only screen and (min-width: 810px) {
  .flow-text {
      font-size:1.56rem;
  }
}

@media only screen and (min-width: 450px) {
  .flow-text {
      line-height:2.36rem;
  }
}

@media only screen and (min-width: 840px) {
  .flow-text {
      font-size:1.584rem;
  }
}

@media only screen and (min-width: 480px) {
  .flow-text {
      line-height:2.464rem;
  }
}

@media only screen and (min-width: 870px) {
  .flow-text {
      font-size:1.608rem;
  }
}

@media only screen and (min-width: 900px) {
  .flow-text {
      font-size:1.632rem;
  }
}

@media only screen and (min-width: 930px) {
  .flow-text {
      font-size:1.656rem;
  }
}

@media only screen and (min-width: 960px) {
  .flow-text {
      font-size:1.68rem;
  }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

